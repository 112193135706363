import { useLocation } from '@reach/router';
import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';

/**
 * Custom hook to return total partner name
 * @returns {number}
 */
function usePartner() {
  const location = useLocation();
  const partner = getQueryValue(location, 'partner');

  return parseInt(partner, 0);
}

export default usePartner;
