import React, { useState } from 'react';
import { string } from 'prop-types';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import useMutation from '@/hooks/useMutation';
import useGuestInfo from '@/hooks/useGuestInfo';
import usePartner from '@hooks/usePartner';

import ImgPattern from '@/assets/pattern/flower.png';
import { API_HOSTNAME, API_RSVP_TABLE } from '@/constants';
import { BUTTON_PROPS, BUTTON_GRADIENT, BG_PRIMARY_DARK_GRADIENT } from '@/constants/colors';
import { txtForm as txt } from './locales';
import {
  FORM_LABEL_PROPS,
  INPUT_COMMON_PROPS,
  SELECT_COMMON_PROPS,
  TYPE,
  ERROR_TYPE,
  ERROR_PROPS,
} from './types';
import { ENABLE_GUEST_PERSONALIZATION, ENABLE_PARTNER_MODE } from '@/constants/feature-flags';
import { ID_RSVP_SECTION } from '@/constants/identifier';
import BorderFrame from '@components/Common/BorderFrame';

const enc = (string) => encodeURIComponent(string);

/**
 * function to render RSVP component
 * @returns {JSX.Element}
 * @author idindrakusuma
 */
function RSVPSection({ lang, ...rest }) {
  const toast = useToast();
  const partner = usePartner();
  const { name: guestName = '' } = useGuestInfo();
  const finalName = guestName.toLowerCase() === 'guest' ? '' : guestName;

  const [onSentConfirmation, loading] = useMutation(API_HOSTNAME, API_RSVP_TABLE, 'insert');

  const [name, setName] = useState(() => finalName);
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('-');
  const [attended, setAttended] = useState(TYPE.YES);
  const [bringPartner, setBringPartner] = useState(1);
  const [errorType, setErrorType] = useState(ERROR_TYPE);

  /**
   * function to set state
   * @param {object} e - html event value
   * @param {function} setState
   * @param {string} typeState
   * @returns
   */
  const onSetState = (e, setState, typeState) => {
    const value = e.target.value;
    setState(value);

    if (typeState === 'email') {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        setErrorType({ ...errorType, email: 'Invalid email address' });
        return;
      }
    }

    if (typeState === 'phone') {
      if (!/\d+/.test(value) || value.length > 13) {
        setErrorType({ ...errorType, phone: 'Invalid phone number' });
        return;
      }
    }

    setErrorType(ERROR_TYPE);
  };

  /**
   * function to submit to BE with check the form value first
   */
  const onSubmitForm = async () => {
    if (errorType.name || errorType.phone || errorType.address) return;

    if (!name || !phone || !address) {
      setErrorType({
        name: !name ? txt.required[lang] : '',
        phone: !phone ? txt.required[lang] : '',
        address: !address ? txt.required[lang] : '',
      });

      return;
    }

    const query =
      `nama=${enc(name)}` +
      `&hp=${phone}` +
      `&hadir=${enc(attended)}` +
      `&jumlah_tamu=${attended === TYPE.NO ? '' : bringPartner}` +
      `&alamat=${enc(address)}`;

    const result = await onSentConfirmation(query);

    if (result.success) {
      toast({
        title: txt.success[lang],
        description: txt.msgSuccess[lang],
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setName('');
      setPhone('');
      setAddress('-');
    } else {
      toast({
        title: 'Oops!',
        description: txt.msgError[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const DEFAULT_BOTTOM = {
    color: 'white',
    marginTop: '32px',
    boxShadow: 'xl',
    fontSize: '14px',
    fontWeight: 'light',
    borderRadius: '6px',
    background: BUTTON_GRADIENT,
    ...BUTTON_PROPS,
  };

  return (
    <Box
      padding="48px 32px"
      bgColor="bgPrimary"
      bgSize="initial"
      bgImage={`linear-gradient(${BG_PRIMARY_DARK_GRADIENT}, ${BG_PRIMARY_DARK_GRADIENT}), url(${ImgPattern})`}
      {...rest}
      name={ID_RSVP_SECTION}
    >
      <WithAnimation>
        <Box
          bgColor="bgSecondary"
          padding="42px 24px"
          borderRadius="16px"
          color="alternativeColorText"
          pos={'relative'}
        >
          <BorderFrame isRSVPFrame isHeader />
          <BorderFrame isRSVPFrame />
          <Box padding="24px 16px" width="100%">
            {/* Title & Desc Section */}
              <Box textAlign="center" color="alternativeColorText">
                <Text fontFamily="heading" fontSize="4xl">
                  {txt.title[lang]}
                </Text>
                <Text fontSize="md" margin="16px 0 24px 0">
                  {txt.desc[lang]}
                </Text>
              </Box>
            {/* Form Sections - Name */}
              <FormControl margin="8px 0" isInvalid={errorType.name}>
                <FormLabel {...FORM_LABEL_PROPS}>{txt.name[lang]}:</FormLabel>
                <Input
                  {...INPUT_COMMON_PROPS}
                  placeholder="..."
                  value={name}
                  onChange={(e) => onSetState(e, setName)}
                />
                <FormErrorMessage {...ERROR_PROPS}>{`* ${errorType.name}`}</FormErrorMessage>
              </FormControl>
            {/* Form Sections - Phone Number */}
              <FormControl margin="16px 0" isInvalid={errorType.phone}>
                <FormLabel {...FORM_LABEL_PROPS}>{txt.hp[lang]}:</FormLabel>
                <Input
                  {...INPUT_COMMON_PROPS}
                  placeholder="..."
                  value={phone}
                  onChange={(e) => onSetState(e, setPhone, 'phone')}
                />
                <FormErrorMessage {...ERROR_PROPS}>{'* ' + errorType.phone}</FormErrorMessage>
              </FormControl>
            {/* Form Sections - Address */}
            {false && (
                <FormControl margin="8px 0" isInvalid={errorType.address}>
                  <FormLabel {...FORM_LABEL_PROPS}>{txt.address[lang]}:</FormLabel>
                  <Input
                    {...INPUT_COMMON_PROPS}
                    height="40px"
                    placeholder="..."
                    value={address}
                    onChange={(e) => onSetState(e, setAddress)}
                  />
                  <FormErrorMessage {...ERROR_PROPS}>{'* ' + errorType.address}</FormErrorMessage>
                </FormControl>
            )}
            {/* Form Sections - Attendance */}
              <FormControl margin="8px 0">
                <FormLabel {...FORM_LABEL_PROPS}>{txt.willYoutAttend[lang]}</FormLabel>
                <Select
                  {...SELECT_COMMON_PROPS}
                  value={attended}
                  onChange={(e) => onSetState(e, setAttended)}
                >
                  <option value={TYPE.YES} style={{ color: 'black' }}>
                    {txt.willAttend[lang]}
                  </option>
                  <option value={TYPE.NO} style={{ color: 'black' }}>
                    {txt.noAttend[lang]}
                  </option>
                </Select>
              </FormControl>
            
            {/* Form Sections - Partner */}
            {attended === TYPE.YES && ENABLE_PARTNER_MODE && (
              <>
                <FormControl>
                  <FormLabel {...FORM_LABEL_PROPS}>{txt.willYouBringPartner[lang]}</FormLabel>
                  <Select
                    {...SELECT_COMMON_PROPS}
                    value={bringPartner}
                    onChange={(e) => onSetState(e, setBringPartner)}
                  >
                    {ENABLE_GUEST_PERSONALIZATION ? (
                      <>
                        {!partner && (
                          <option value={1} style={{ color: 'black' }}>
                            1
                          </option>
                        )}
                        {partner === 1 && (
                          <option value={1} style={{ color: 'black' }}>
                            1
                          </option>
                        )}
                        {partner === 2 && (
                          <>
                            <option value={1} style={{ color: 'black' }}>
                              1
                            </option>
                            <option value={2} style={{ color: 'black' }}>
                              2
                            </option>
                          </>
                        )}
                        {partner === 3 && (
                          <>
                            <option value={1} style={{ color: 'black' }}>
                              1
                            </option>
                            <option value={2} style={{ color: 'black' }}>
                              2
                            </option>
                            <option value={3} style={{ color: 'black' }}>
                              3
                            </option>
                          </>
                        )}
                        {partner === 4 && (
                          <>
                            <option value={1} style={{ color: 'black' }}>
                              1
                            </option>
                            <option value={2} style={{ color: 'black' }}>
                              2
                            </option>
                            <option value={3} style={{ color: 'black' }}>
                              3
                            </option>
                            <option value={4} style={{ color: 'black' }}>
                              4
                            </option>
                          </>
                        )}
                        {partner > 4 && (
                          <>
                            <option value={1} style={{ color: 'black' }}>
                              1
                            </option>
                            <option value={2} style={{ color: 'black' }}>
                              2
                            </option>
                            <option value={3} style={{ color: 'black' }}>
                              3
                            </option>
                            <option value={4} style={{ color: 'black' }}>
                              4
                            </option>
                            <option value={5} style={{ color: 'black' }}>
                              5
                            </option>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <option value={1} style={{ color: 'black' }}>
                          1
                        </option>
                        <option value={2} style={{ color: 'black' }}>
                          2
                        </option>
                      </>
                    )}
                  </Select>
                  {/* SET to TRUE if you want to add some information if user bring-partner YES */}
                  {TYPE.YES && false && (
                    <FormHelperText color="mainColorText" fontSize="10px" fontStyle="italic">
                      {txt.singleGuestInfo[lang]}
                    </FormHelperText>
                  )}
                </FormControl>
              </>
            )}
              <Center>
                <Button
                  size="sm"
                  isLoading={loading}
                  fontSize="16px"
                  bgColor="bgSecondary"
                  color="secondaryColorText"
                  marginBottom="16px"
                  onClick={onSubmitForm}
                  //fontFamily="body"
                  {...DEFAULT_BOTTOM}
                >
                  {txt.submit[lang]}
                </Button>
              </Center>
            <Box pos={'relative'}>
              <BorderFrame isRSVPFrame isFooter left="-28px" top="-6px" w="calc(100% + 56px)" />
            </Box>
          </Box>
        </Box>
      </WithAnimation>
    </Box>
  );
}

RSVPSection.propTypes = {
  lang: string,
};

RSVPSection.defaultProps = {
  lang: 'id',
};

export default React.memo(RSVPSection);
